import UserDashboard from "@/components/UserDashboard.tsx";
import ActionEditorCard from "@/features/lending/components/ActionEditorCard.tsx";
import ApproveCard from "@/features/lending/components/ApproveCard.tsx";
import BalanceInputCard from "@/features/lending/components/BalanceInputCard.tsx";
import DesktopMarketTable from "@/features/lending/components/DesktopMarketTable.tsx";
import LendingStatBanner from "@/features/lending/components/LendingStatBanner.tsx";
import MobileActionEditor from "@/features/lending/components/MobileActionEditor.tsx";
import MobileActionSelector from "@/features/lending/components/MobileActionSelector.tsx";
import MobileBalanceEditor from "@/features/lending/components/MobileBalanceEditor.tsx";
import MobileMarketTable from "@/features/lending/components/MobileMarketTable.tsx";
import useMediaQuery from "@/hooks/useMediaQuery.ts";
import { usePool } from "@/hooks/usePool.ts";
import TxBuilderProvider, { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { Market, TxPreparation } from "@/types.ts";
import { isWrappedMarket } from "@/utils/wrappedMarket.ts";
import {
  Box,
  Card,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Grid,
  GridItem,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";
import { find, isEmpty, xorBy } from "lodash-es";
import { useContext, useState } from "react";
import { isAddressEqual } from "viem";

const Lending = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { txs } = useContext(TxBuilderContext);
  const {
    isOpen: isOpenActionSelect,
    onOpen: onOpenActionSelect,
    onClose: onCloseActionSelect,
  } = useDisclosure();
  const {
    isOpen: isOpenBalanceInput,
    onOpen: onOpenBalanceInput,
    onClose: onCloseBalanceInput,
  } = useDisclosure();
  const { pool } = usePool();

  const [txPreparation, setTxPreparation] = useState<TxPreparation>();
  const [selectedMarket, setSelectedMarket] = useState<Market>();

  const [wrappedMarkets, setWrappedMarkets] = useState<Market[]>();

  const onPrepareTx = (txPreparation: TxPreparation) => {
    setTxPreparation(txPreparation);
  };

  const onCloseTx = () => {
    setTxPreparation(undefined);
  };

  const onToggleWrappedMarket = (market: Market) => {
    const newUnwrappedMarkets = xorBy(
      wrappedMarkets,
      [market],
      (m) => m.market,
    );

    setWrappedMarkets(newUnwrappedMarkets);
  };

  const usingUnwrappedMarket = (market: Market) => {
    return (
      isWrappedMarket(market, pool) &&
      !find(wrappedMarkets, (um) => isAddressEqual(um.market, market.market))
    );
  };

  return (
    <Box bgColor="background.light" pb={6}>
      <Box
        py={2}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="ui.line"
      >
        <Container maxW="container.xl">
          <Text
            color="white"
            textAlign="center"
            fontWeight="bold"
            sx={{ textWrap: "pretty" }}
          >
            Total of 42,799 @BLAST Gold up for grabs in Blast pool{" "}
            <a
              href="https://x.com/aliendotfinance/status/1838193794287534432"
              target="_blank"
            >
              (more info)
            </a>
          </Text>
        </Container>
      </Box>
      <Container maxW="container.xl">
        <LendingStatBanner />
        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 5, lg: 3 }}>
            <Card py={6}>
              <UserDashboard />
              <Divider my={4} borderColor="ui.tag" opacity="0.2" />
              {isMobile ? (
                <MobileMarketTable
                  onMarketSelect={(market) => {
                    setSelectedMarket(market);
                    onOpenActionSelect();
                  }}
                  usingUnwrappedMarket={usingUnwrappedMarket}
                />
              ) : (
                <DesktopMarketTable
                  onPrepareTx={onPrepareTx}
                  onToggleWrappedMarket={onToggleWrappedMarket}
                  usingUnwrappedMarket={usingUnwrappedMarket}
                />
              )}
            </Card>
          </GridItem>
          {isDesktop && (
            <GridItem colSpan={2}>
              <Stack spacing={6}>
                {txPreparation && (
                  <BalanceInputCard
                    txPreparation={txPreparation}
                    onCloseTx={onCloseTx}
                  />
                )}
                <ApproveCard />
                <ActionEditorCard
                  onEditTx={(tx) => {
                    onPrepareTx({
                      market: tx.market,
                      marketAction: tx.action,
                      amount: tx.amount,
                      useUnwrappedMarket: tx.useUnwrappedMarket,
                    });
                  }}
                />
              </Stack>
            </GridItem>
          )}
        </Grid>
      </Container>
      {isMobile && !isEmpty(txs) && (
        <MobileActionEditor
          onEditTx={(tx) => {
            onPrepareTx({
              market: tx.market,
              marketAction: tx.action,
              amount: tx.amount,
              useUnwrappedMarket: tx.useUnwrappedMarket,
            });
            onCloseActionSelect();
            onOpenBalanceInput();
          }}
        />
      )}
      {isMobile && (
        <Drawer
          isOpen={isOpenActionSelect}
          onClose={onCloseActionSelect}
          placement="bottom"
        >
          <DrawerOverlay />
          <DrawerContent bgColor="transparent">
            <DrawerBody
              pt={6}
              pb={8}
              bgColor="bg.modal"
              border="1px solid"
              borderColor="ui.line"
              borderRadius="6px 6px 0 0"
            >
              {selectedMarket && (
                <MobileActionSelector
                  market={selectedMarket}
                  onPrepareTx={(txPreparation) => {
                    onPrepareTx(txPreparation);
                    onCloseActionSelect();
                    onOpenBalanceInput();
                  }}
                  usingUnwrappedMarket={usingUnwrappedMarket}
                  onToggleWrappedMarket={onToggleWrappedMarket}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {isMobile && (
        <Drawer
          isOpen={isOpenBalanceInput}
          onClose={onCloseBalanceInput}
          placement="bottom"
        >
          <DrawerOverlay />
          <DrawerContent bgColor="transparent">
            <DrawerBody
              pt={6}
              pb={8}
              bgColor="bg.modal"
              border="1px solid"
              borderColor="ui.line"
              borderRadius="6px 6px 0 0"
            >
              {txPreparation && (
                <MobileBalanceEditor
                  txPreparation={txPreparation}
                  onClose={() => {
                    onCloseTx();
                    onCloseBalanceInput();
                  }}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

const WrappedLending = () => {
  return (
    <TxBuilderProvider>
      <Lending />
    </TxBuilderProvider>
  );
};

export default WrappedLending;

export const Route = createFileRoute("/lending")({
  component: WrappedLending,
});
